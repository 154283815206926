.containerMain {
  width: 100%;
}

.hiddenButton {
  width: 100%;
  height: 100%;
  cursor: inherit;
}

@media (min-width: 1024px) {
  .containerMain {
    padding: 40px 104px;
    background: #fafafa;
  }
}

.line {
  background-color: #e0e0e0;
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.lineModal {
  margin: 16px 0 0 0;
  background-color: #e0e0e0;
  height: 1px;
  width: 100%;
}

.title {
  color: #757575;
  font-family: 'Inter Regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.0025em;
  line-height: 28px;
  text-align: left;
  margin: 16px 0 4px 0;
}

.productsMobile {
  position: relative;
}

.productSummaryImg {
  border-radius: 8px;
}

.modal {
  max-height: 100%;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  position: 99;
  z-index: 1000;
}

.buttonProducts {
  position: absolute;
  background-color: #ffffff;
  width: 109px;
  height: 24px;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  bottom: -12px;
}

.container {
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 99;
}

.resumeTitleModal {
  font-family: 'Inter Regular', sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #212121;
  width: 100%;
}

.modalHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
}

.formCoupon {
  width: 100%;
}

.containerCoupon {
  width: 100%;
  position: relative;
}

.containerCoupon div div {
  background-color: #ffffff;
}

.inputCoupon::placeholder {
  font-size: 12px;
}

.couponButton {
  position: absolute;
  top: 14px;
  right: 12px;
  z-index: 20;
}

.couponsContainer {
  width: 100%;
  margin-bottom: 10px;
}

.couponsContainer p {
  font-family: 'Inter Regular', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  letter-spacing: 0.0025em;
}

.promoContainer {
  font-family: 'Inter Regular', sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.004em;
  background: #eeeeee;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  margin-right: 10px;
  margin-bottom: 20px;
}

.promoContainer span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.promoContainer button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 12px;
  padding: 0;
}

@media (min-width: 1024px) {
  .title {
    font-size: 20px;
    letter-spacing: 0.005em;
  }
}

@media (max-width: 450px) {
  .line {
    background-color: #e0e0e0;
    height: 1px;
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.collapTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #4f50f6;
  margin-left: 10px;
  margin-right: 10px;
}

.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 20px;
  margin-bottom: 12px;
}

.productQuantity {
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 11px;
  margin-left: 16px;
}

.totalPrice {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.collapButton {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.collapButton .chevron {
  transform: rotate(180deg);
}

svg {
  width: 100px;
  height: 100px;
  background-color: red;
}
svg * {
  width: 100%;
  height: 100%;
  fill: blue;
}

.expanded {
  width: 100%;
  max-height: 1000px;
  transition: max-height 0.8s ease;
  overflow: hidden;
  position: relative;
}

.collapsed {
  max-height: 1px;
  width: 100%;
  transition: max-height 0.8s ease;
  overflow: hidden;
  position: relative;
}
