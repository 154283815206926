.container {
  background-color: #f5f5f5;
  padding: 16px;
  position: relative;
}

.containerPayPal {
  margin: 0 16px;
}

.containerButtonPayPal > div {
  z-index: 0;
}

.containerTitlePayPal {
  background: #fafafa;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  margin-bottom: 16px;
}

.containerTitlePayPal > p {
  margin: 0;
}

.titleContainer {
  padding: 16px;
  position: relative;
  padding-bottom: 7px;
}

.title {
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #212121;
  margin-bottom: 16px;
}

.text {
  color: #757575;
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  letter-spacing: 0.005em;
  line-height: 24px;
}

.logo {
  position: absolute;
  top: 16px;
  right: 16px;
}

.logoCards {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
}

.logoCards img {
  margin-right: 4px;
}

.logoPayPal {
  width: 96px;
  height: 25px;
  margin: 24px 16px;
}

.securedContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secured {
  display: flex;
}

.securedText {
  font-family: 'Inter Regular', sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #616161;
  letter-spacing: 0.004em;
  margin-left: 11px;
}

.circle {
  width: 24px;
  height: 24px;
  background-color: #05a357;
  border-radius: 100%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  padding: 6px;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.circleTitle {
  margin-right: -5px;
  background-color: #5298f5;
  width: 20px;
  height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 100%;
}

.circleTitle p {
  font-family: 'Poppins Normal', sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0015em;
}

.list .text {
  margin: 18px 0;
  display: flex;
}

.stepText {
  max-width: 90%;
}

.button {
  margin-top: 24px;
  padding: 0 16px;
}

@media (min-width: 1024px) {
  .button {
    padding: 0;
  }
  .circleTitle {
    margin-left: -16px;
  }
}

.containerPolicy {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.policy {
  font-family: 'Inter Regular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin-left: 8px;
}

.textPreventAddress {
  font-family: 'Inter Regular', sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.004em;
  color: #757575;
  margin-bottom: 16px;
  text-align: center;
}

.disclaimer {
  margin-bottom: 16px;
  padding: 0 16px;
}

.disclaimer div {
  max-width: 100%;
}

.containerSlider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: scroll;
  margin-top: 24px;
}

.containerSlider .method {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin-right: 8px;
  margin-right: 8px;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 8px;
  cursor: pointer;
  width: 162px;
  height: 68px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.containerSlider .method:hover {
  border: 2px solid #4f50f6;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 8px;
  cursor: pointer;
}

.containerSlider .activeMethod {
  border: 2px solid #4f50f6;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 8px;
}

.containerSlider .activeMethod span {
  color: red;
}

::-webkit-scrollbar {
  display: none;
}

.label {
  margin-left: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

@media (max-width: 1024px) {
  .containerSlider {
    margin-right: 16px;
    margin-left: 16px;
  }
}

@media (max-width: 450px) {
  .containerSlider {
    justify-content: unset;
    padding: 8px 1px;
  }

  .containerSlider .method {
    flex-grow: unset;
    margin-right: 15px;
    margin-right: 13px;
  }
}
